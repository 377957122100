/*
 * @Author: 刘晓容
 * @Date: 2022-04-12 11:02:33
 * @LastEditors: 刘晓容
 * @LastEditTime: 2022-04-27 09:59:45
 * @FilePath: \hui-keeper-h5\src\pages\index.tsx
 */
import { lazy } from "react";

const FrontPage = lazy(() => import("./front-page")); // 首页
const NoFoundPage = lazy(() => import("./404")); // 404页面
const Register = lazy(() => import("./register")); // 注册
const Login = lazy(() => import("./login")); // 登录
const Risk = lazy(() => import("./risk")); // 风险分布
const RiskAssessment = lazy(() => import("./risk-assessment")); // 工单险风险评估报告
const RiskAssessmentDetail = lazy(() => import("./risk-assessment-detail")); // 工单险风险评估报告-详情
const Insure = lazy(() => import("./insure")); // 保单列表
const InsureDetail = lazy(() => import("./insure-detail")); // 保单详情
const Endorse = lazy(() => import("./endorse")); // 批单列表
const Claim = lazy(() => import("./claim")); // 理赔列表
const EndorseDetail = lazy(() => import("./endorse-detail")); // 批单详情
const ClaimDetail = lazy(() => import("./claim-detail")); // 理赔详情
const FollowRecord = lazy(() => import("./follow-record")); // 跟进记录
const HomePage = lazy(() => import("./home")); // 首页
const Agreement = lazy(() => import("./agree/agreement")); // 用户注册协议
const Secret = lazy(() => import("./agree/secret")); // 隐私权政策
const User = lazy(() => import("./user")); // 个人中心/账户管理
const Enterprise = lazy(() => import("./enterprise")); // 企业信息
const PersonalInfo = lazy(() => import("./personal-info")); // 个人资料
const NewAccount = lazy(() => import("./new-account")); // 新增账号
const PasswordSetting = lazy(() => import("./password-setting")); // 密码设置
const EnterpriseAccount = lazy(() => import("./enterprise-account")); // 企业账户
const DeleteAccount = lazy(() => import("./delete-account")); // 删除账号
const RFQ = lazy(() => import("./rfq")); // 询报价
const ClimateWarning = lazy(() => import("./climate-warning")); // 气候预警
const City = lazy(() => import("./city")); // 城市选择
const CitySet = lazy(() => import("./city-set")); // 区域设置
const DisasterGuide = lazy(() => import("./disaster-guide")); // 防灾指引
const PVInsurance = lazy(() => import("./pv-insurance")); // 光伏保险
const GuangWuDi = lazy(() => import("./guang-wu-di")); // 光伏保险
const PVLogin = lazy(() => import("./pv-login")); // 光伏保险-登录
const MyPolicy = lazy(() => import("./my-policy")); // 我的保单(光伏保险)
const MyPolicyDetail = lazy(() => import("./my-policy-detail")); // 我的保单详情(光伏保险)
const Activity = lazy(() => import("./activity")); // 我的保单详情(光伏保险)
const Xiaojinwu = lazy(() => import("./activity/xiaojinwu")); // 我的保单详情(光伏保险)
// chargingStation
const ChargingStation = lazy(() => import("./activity/chargingStation")); // 我的保单详情(光伏保险)
const WorkOrderRisk = lazy(() => import("./work-order-risk")); // 工单风险综览
const InsureOrder = lazy(() => import("./insure-order")); // 工单投保订单
const ClaimCase = lazy(() => import("./claim-case")); // 工单理赔案件列表
const ClaimCaseDetail = lazy(() => import("./claim-case-detail")); // 工单理赔案件详情
const ProcessRecord = lazy(() => import("./process-record")); // 处理记录
const ComplaintUpdate = lazy(() => import("./complaint-update")); // 赔案更新
const MonthReport = lazy(() => import("./month-report")); // 月度报告
const MonthRiskDetail = lazy(() => import("./month-risk-detail")); // 月度风险报告详情
const Guangfu = lazy(() => import("./guangfu")); // 月度风险报告详情
// guangfuPolicyDetail
// const GuangfuPolicyDetail = lazy(() => import("./guangfuPolicyDetail")); // 光伏保单详情
const GuangfuCase = lazy(() => import("./guangfu-case")); // 光伏保单详情
const GuangfuPolicyDetail = lazy(() => import("./guangfuPolicyDetail")); // 月度风险报告详情
const EndorseGuangFu = lazy(() => import("./endorse-guangfu")); // 光伏批单列表
const EndorseDetailGuangFu = lazy(() => import("./endorse-detail-guangfu")); // 批单详情
const pvclaimcasedetail = lazy(() => import("./pv-claim-case-detail")); // 批单详情
const Extension = lazy(() => import('./extension'))
const WorkOrderInsurance = lazy(() => import('./work-order-insurance'))
const SubmitSuccess = lazy(()=> import('./submit-success'))
const Skyworth = lazy(()=> import('./Skyworth'))
// Skyworth-information-gathering
const SkyworthInformationGathering = lazy(()=> import('./Skyworth-information-gathering'))
// Skyworth-submit-success
const SkyworthSubmitSuccess = lazy(()=> import('./Skyworth-submit-success'))
const SFDJInformationGathering = lazy(()=> import('./sfdj-information-gathering'))
// sfdj-submit-success
const SFDJSubmitSuccess = lazy(()=> import('./sfdj-submit-success'))
const PvReport = lazy(()=> import('./pv-report'))
const PvReportDetail = lazy(()=> import('./pv-report-detail'))
// casualty-insurance
const CasualtyInsurance = lazy(()=> import('./casualty-insurance'))
// Skyworth
// casualty-insurance-detail
const casualtyInsuranceDetail = lazy(()=> import('./casualty-insurance-detail'))
// casualty-insurance-list
const casualtyInsuranceList = lazy(()=> import('./casualty-insurance-list'))
export {
	FrontPage,
	NoFoundPage,
	Register,
	Login,
	Risk,
	RiskAssessment,
	RiskAssessmentDetail,
	Insure,
	InsureDetail,
	Endorse,
	Claim,
	EndorseDetail,
	ClaimDetail,
	FollowRecord,
	HomePage,
	Agreement,
	Secret,
	User,
	Enterprise,
	PersonalInfo,
	NewAccount,
	PasswordSetting,
	EnterpriseAccount,
	DeleteAccount,
	RFQ,
	ClimateWarning,
	City,
	CitySet,
	DisasterGuide,
	PVInsurance,
	PVLogin,
	MyPolicy,
	MyPolicyDetail,
	Activity,
	WorkOrderRisk,
	InsureOrder,
	ClaimCase,
	ClaimCaseDetail,
	ProcessRecord,
	ComplaintUpdate,
	MonthReport,
	MonthRiskDetail,
	Xiaojinwu,
	Guangfu,
	GuangfuPolicyDetail,
	GuangfuCase,
	EndorseGuangFu,
	EndorseDetailGuangFu,
	pvclaimcasedetail,
	Extension,
	WorkOrderInsurance,
	SubmitSuccess,
	ChargingStation,
	GuangWuDi,
	Skyworth,
	SkyworthInformationGathering,
	SkyworthSubmitSuccess,
	PvReport,
	PvReportDetail,
	SFDJInformationGathering,
	SFDJSubmitSuccess,
	CasualtyInsurance,
	casualtyInsuranceDetail,
	casualtyInsuranceList
};

